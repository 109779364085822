<template>
  <match-media v-slot="{ desktop, tablet, mobile }">
    <aside v-if="!mobile" class="users__bulk-edit">
      <v-drawer position="right" without-background :visible.sync="bulkEditVisible">
        <user-bulk-edit-dialog
          :user="selectedUsersEdit[0]"
          @close="bulkEditVisible = false"
          @archive="archiveUser"
          @activate="activateUser"
          @submitted="editUserSubmit"
          @cancel-invite="cancelInvite"
        ></user-bulk-edit-dialog>
      </v-drawer>
    </aside>
    <div class="users">
      <div ref="usersTopWrapper" class="users__top-wrapper">
        <div class="users__header">
          <v-page-title class="users__title">
            Пользователи
            <span class="users__count">{{ params.count }}</span>
          </v-page-title>
          <v-button primary class="users__button" @click="addUser">
            Пригласить
          </v-button>
        </div>

        <div class="users__tabs-wrapper">
          <v-tabs :value="queryParams.status" :primary="mobile" class="users__tabs" @input="changeStatus">
            <v-tab v-for="(userStatus, index) of $options.STATUSES_MAP" :key="index" :name="userStatus.value">
              {{ userStatus.label }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <section class="users__list">
        <template v-if="!mobile">
          <users-table
            :table-height="tableHeight"
            :infinite-loading="infiniteLoading"
            :list="list"
            :infinite-id="infiniteId"
            @next-page-request="nextPageRequest"
            @bulk-edit="bulkEdit"
            @add-plan="addAgentPlan"
          ></users-table>
        </template>
        <template v-else>
          <user-list-item
            v-for="user in usersList"
            :key="user.id"
            v-bind="user"
            @archive="archiveUser(user.id)"
            @activate="activateUser(user.id)"
            @edit="editUser(user)"
            @add-plan="addAgentPlan(user)"
            @cancel-invite="cancelInvite(user.id)"
          />
          <infinite-loading force-use-infinite-wrapper :identifier="infiniteId" @infinite="infiniteHandler">
            <template #no-more><span></span></template>
            <template #no-results><span></span></template>
          </infinite-loading>
        </template>
      </section>

      <user-edit-dialog :visible.sync="isEditDialogVisible" :user-data="model" @submitted="editUserSubmit" />
      <agent-plan-dialog :visible.sync="isEditPlanDialogVisible" :agent-data="model" />
    </div>
  </match-media>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import UserEditDialog from '@/components/User/EditDialog.vue'
import AgentPlanDialog from '@/components/Agent/PlanDialog.vue'
import UserListItem from '@/components/User/ListItem.vue'
import VButton from '@/components/common/VButton.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import { authService } from '@/services/http'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { loadingService } from '@/services/loading'
import redirectIfNetworkIssue from '@/router/utils'
import { USER_STATUS_ACTIVE, USER_STATUS_ARCHIVE } from '@/constants/statuses/users'
import { getCalculatingHeightTable } from '@/utils/calculatingHeightTable'
import CancelSourceManager from '@/services/http/CancelSourceManager'
import UsersTable from '@/components/User/Table.vue'
import { MatchMedia } from 'vue-component-media-queries'
import VDrawer from '@/components/common/VDrawer.vue'
import UserBulkEditDialog from '@/components/User/BulkEditDialog.vue'

const STATUSES_MAP = [
  { label: 'Актуальные', value: USER_STATUS_ACTIVE },
  { label: 'Архив', value: USER_STATUS_ARCHIVE }
]

export default {
  name: 'Users',
  STATUSES_MAP,
  components: {
    UserBulkEditDialog,
    VDrawer,
    InfiniteLoading,
    MatchMedia,
    UsersTable,
    UserEditDialog,
    UserListItem,
    AgentPlanDialog,
    VButton,
    VPageTitle,
    VTabs,
    VTab
  },
  data: () => {
    return {
      infiniteId: +new Date(),
      infiniteLoading: false,
      tableHeight: 0,
      model: {},
      loading: false,
      list: [],
      params: {
        count: 0,
        offset: 0,
        limit: 10
      },
      isEditDialogVisible: false,
      isEditPlanDialogVisible: false,
      bulkEditVisible: false,
      selectedUsersEdit: []
    }
  },
  computed: {
    usersList() {
      return this.list.map(el => ({ ...el, status: this.queryParams.status }))
    },
    queryParams() {
      return {
        status: this.$route.query?.status || USER_STATUS_ACTIVE
      }
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!from.name) {
        vm.fetchUsers()
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.resetUsersList()
      this.fetchUsers()
    })
    next()
  },
  mounted() {
    const { usersTopWrapper } = this.$refs
    this.tableHeight = getCalculatingHeightTable(usersTopWrapper)
  },
  methods: {
    bulkEdit(users) {
      if (users.length) {
        this.selectedUsersEdit = users
        this.bulkEditVisible = true
      } else {
        this.selectedUsersEdit = []
        this.bulkEditVisible = false
      }
    },
    infiniteHandler($state) {
      this.nextPageRequest($state)
    },
    editUserSubmit() {
      this.resetUsersList()
      this.fetchUsers()
    },
    resetUsersList() {
      this.model = {}
      this.params.offset = 0
      this.list = []
    },
    nextPageRequest(infinityState) {
      if (this.list.length) this.fetchUsers({ infinityState })
    },
    fetchUsers({ infinityState, ...params } = {}) {
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-users', true)

      const fetchParams = { ...this.queryParams, ...params }
      const { limit, offset } = this.params

      runWithCancel(({ cancelSource }) => {
        if (!infinityState) this.loading = true
        else this.infiniteLoading = true
        return authService.getUsersList({ limit, offset, cancelToken: cancelSource.token, ...fetchParams })
      })
        .then(({ count, results }) => {
          this.params = { ...this.params, count }
          if (results.length) {
            this.list.push(...results)
            this.params.offset += this.params.limit
            if (infinityState) {
              infinityState.loaded()
            } else {
              this.infiniteId += 1 // сброс скролла на текущее значение
            }
            return
          }
          if (infinityState) infinityState.complete()
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
          this.infiniteLoading = false
        })
    },
    updateQueryParams({ status }) {
      const params = { status }
      this.$router.push({ query: params })
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    addUser() {
      this.model = {}
      this.isEditDialogVisible = true
    },
    addAgentPlan(user) {
      this.model = user
      this.isEditPlanDialogVisible = true
    },
    editUser(user) {
      this.model = user
      this.isEditDialogVisible = true
    },
    cancelInvite(userId) {
      confirmService
        .ask({ title: 'Вы точно хотите отменить приглашение?' })
        .then(() => {
          this.loading = true
          authService
            .cancelUserInvite(userId)
            .then(() => {
              this.editUserSubmit()
              noticeService.success('Приглашение отменено!')
            })
            .catch(() => noticeService.error('Не удалось отмененить приглашение'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    archiveUser(userId) {
      confirmService
        .ask({ title: 'Вы точно хотите архивировать пользователя?' })
        .then(() => {
          this.loading = true
          authService
            .updateUser(userId, { isArchived: true })
            .then(() => {
              this.editUserSubmit()
              noticeService.success('Пользователь архивирован!')
            })
            .catch(() => noticeService.error('Не удалось архивировать пользователя'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    activateUser(userId) {
      confirmService
        .ask({ title: 'Вы точно хотите активировать пользователя?' })
        .then(() => {
          this.loading = true
          authService
            .updateUser(userId, { isArchived: false })
            .then(() => {
              this.editUserSubmit()
              noticeService.success('Пользователь активирован!')
            })
            .catch(() => noticeService.error('Не удалось активировать пользователя'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
