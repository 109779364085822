<template>
  <v-table-row class="user-item">
    <v-avatar class="user-item__cell user-item__cell--user-avatar" size="small" :src="photo" />
    <div class="user-item__row-data">
      <div class="user-item__cell user-item__cell--user-name">{{ name }}</div>
      <div class="user-item__cell user-item__cell--phone">{{ phoneMask }}</div>
      <div class="user-item__cell user-item__cell--email">{{ email }}</div>
      <div
        v-if="role === $options.USER_STATUS_AGENT.value && !isPending"
        class="user-item__cell user-item__cell--results"
      >
        <el-progress
          type="circle"
          :percentage="agentResults.show"
          :color="$options.variables.agentShowsProgressColor"
          :width="24"
          :stroke-width="2"
        ></el-progress>
        <el-progress
          type="circle"
          :percentage="agentResults.advert"
          :color="$options.variables.agentObjectsProgressColor"
          :width="24"
          :stroke-width="2"
        ></el-progress>
      </div>
    </div>

    <div class="user-item__actions" @click.stop>
      <v-popover-actions class="user-item__popover">
        <v-button-icon class="user-item__action">
          <v-icon-dots />
        </v-button-icon>
        <template #actions="{ onClick }">
          <v-button-wide v-if="role === $options.USER_STATUS_AGENT.value && !isPending" @click="onClick(addPlan)"
            >План Агента</v-button-wide
          >
          <v-button-wide @click="onClick(edit)">{{ isPending ? 'Отправить повторно' : 'Редактировать' }}</v-button-wide>
          <template v-if="!isPending">
            <v-button-wide v-if="isActive" @click="onClick(archive)">Архивировать</v-button-wide>
            <v-button-wide v-if="isArchive" @click="onClick(activate)">Активировать</v-button-wide>
          </template>
          <v-button-wide v-else @click="onClick(cancelInvite)">Отменить приглашение</v-button-wide>
        </template>
      </v-popover-actions>
    </div>
  </v-table-row>
</template>

<script>
import VTableRow from '@/components/common/VTableRow.vue'
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { formatPhone } from '@/utils/formatters'
import { USER_STATUS_ACTIVE, USER_STATUS_ARCHIVE } from '@/constants/statuses/users'
import { USER_STATUS_AGENT } from '@/constants/userStatuses'
import variables from '@/assets/scss/index.scss'
import { getPercentage } from '@/utils/common'

export default {
  USER_STATUS_AGENT,
  variables,
  name: 'UserListItem',
  components: {
    VAvatar,
    VTableRow,
    VIconDots,
    VButtonIcon,
    VPopoverActions,
    VButtonWide
  },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    phone: { type: [Number, String], default: null },
    email: { type: String, required: true },
    photo: { type: String, default: null },
    status: { type: String, required: true },
    role: { type: String, required: true },
    isPending: { type: Boolean, default: false },
    agentProfile: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userStatus: { type: String, required: true }
  },
  computed: {
    agentResults() {
      const { currentPlan } = this.agentProfile
      return {
        advert: currentPlan ? getPercentage(currentPlan.actualAdvertsCount, currentPlan.advertsCount) : 0,
        show: currentPlan ? getPercentage(currentPlan.actualShowsCount, currentPlan.showsCount) : 0
      }
    },
    phoneMask() {
      return this.phone ? formatPhone(this.phone) : ''
    },
    isActive() {
      return this.status === USER_STATUS_ACTIVE
    },
    isArchive() {
      return this.status === USER_STATUS_ARCHIVE
    }
  },
  methods: {
    archive() {
      this.$emit('archive')
    },
    activate() {
      this.$emit('activate')
    },
    edit() {
      this.$emit('edit')
    },
    cancelInvite() {
      this.$emit('cancel-invite')
    },
    addPlan() {
      this.$emit('add-plan')
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/media';
@import '../../assets/scss/mixins';

.user-item {
  $this: &;

  padding-left: 24px;
  padding-right: 24px;

  &__row-data {
    margin-top: 4px;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    flex-direction: column;
    align-items: flex-start;
  }

  &__cell {
    @include linear-text;

    &--user-avatar {
      margin-right: 4px;
    }

    &--user-name {
      font-weight: $--font-weight-medium;
      margin-bottom: 12px;
      width: 100%;
    }

    &--phone {
      margin-bottom: 12px;
    }
    &--results {
      position: absolute;
      right: 24px;
      bottom: 16px;
      display: flex;
      gap: 4px;
      .el-progress__text {
        font-size: 8px !important;
      }
    }

    &--email {
      width: 70%;
    }
  }
}
</style>
